import React from "react"

import TestimonialsItem from "../../../../components/TestimonialsItem"
import * as styles from "./Testimonials.module.scss"

const testimonials = [
  {
    text:
      "Jag samarbetar med Citadellet för hjälp med snabblikvidationer åt mina kunders räkning. Jag får alltid snabb och bra service, oftast inom bara några timmar.",
    author: "Olle Rydqvist",
    position: "",
  },
  {
    text:
      "Jag rekommenderar gärna Citadellet Likvidationer om jag skulle träffa på någon i vänkretsen som skulle behöva hjälp i liknande frågor.",
    author: "Lisbet de Besche",
    position: "",
  },
]

const Testimonials = () => {
  return (
    <div className={styles.MultilayerBgBlock}>
      <div className={styles.wrapper}>
        <div className={styles.lightGreenTrapezoid}>
          <div className={styles.darkGreenTrapezoid}>
            <div className={styles.content}>
              <div className={styles.inner}>
                <h2 className={styles.title}>Vad säger våra kunder?</h2>
                <div className={styles.underLine}></div>
                {/* <p className={styles.text}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor
                </p> */}
                {/* <button className={styles.button}>
                  Read more testimonials
                </button> */}
              </div>
              <div className={styles.testimonials}>
                {testimonials.length &&
                  testimonials.map((item, index) => (
                    <TestimonialsItem
                      text={item.text}
                      author={item.author}
                      position={item.position}
                      key={index}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <p className={styles.title}>
              NÅGRA ORD FRÅN <br />
              vår ledning
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
