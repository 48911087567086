// extracted by mini-css-extract-plugin
export var ServicesHero = "ServicesHero-module--ServicesHero--m3hC3";
export var button = "ServicesHero-module--button--5aYRa";
export var line = "ServicesHero-module--line--tF6st";
export var mobileDisplayContainer = "ServicesHero-module--mobile-display-container--fTD3x";
export var mobileBuyCardContainer = "ServicesHero-module--mobile-buy-card-container--2qRjM";
export var mobileBuyContentContainerTop = "ServicesHero-module--mobile-buy-content-container-top--DWyyj";
export var mobileLine = "ServicesHero-module--mobile-line--1vsrb";
export var mobileSellCardContainer = "ServicesHero-module--mobile-sell-card-container--1D9VW";
export var mobileSellContentContainerTop = "ServicesHero-module--mobile-sell-content-container-top--AdJLp";
export var tabletDisplayContainer = "ServicesHero-module--tablet-display-container--1FGGA";
export var tabletContentContainer = "ServicesHero-module--tablet-content-container--kOB6J";
export var tabletLine = "ServicesHero-module--tablet-line--_Mqya";
export var regularDisplayContainer = "ServicesHero-module--regular-display-container--tX11c";
export var panelContainer = "ServicesHero-module--panel-container--17uyv";
export var lowerPanelBackground = "ServicesHero-module--lower-panel-background--2As0m";
export var contentContainerHero = "ServicesHero-module--content-container-hero--1RcIA";
export var lowerPanel = "ServicesHero-module--lower-panel--1qKqN";
export var lineBuy = "ServicesHero-module--line-buy--244eJ";
export var lineSell = "ServicesHero-module--line-sell--1EcbV";
export var sellCardContainer = "ServicesHero-module--sell-card-container--1Qq5L";
export var buyCardContainer = "ServicesHero-module--buy-card-container--2lxpn";
export var hoverPopupContainer = "ServicesHero-module--hover-popup-container--1JkFa";
export var hoverPopupTextContainer = "ServicesHero-module--hover-popup-text-container--1HRzM";
export var rightContainer = "ServicesHero-module--rightContainer--3NPRf";
export var rightContent = "ServicesHero-module--rightContent--3eXmH";
export var title = "ServicesHero-module--title--3I6q_";
export var link = "ServicesHero-module--link--2oXQq";
export var text = "ServicesHero-module--text--2Ichf";
export var hoverPopupText = "ServicesHero-module--hover-popup-text--y5tyZ";
export var hoverPopupTextLine = "ServicesHero-module--hover-popup-text-line--1h1C-";
export var tabletTitle = "ServicesHero-module--tablet-title--3g-yr";
export var tabletText = "ServicesHero-module--tablet-text--2TWO-";
export var lowerContainer = "ServicesHero-module--lower-container--9brBF";
export var textNextToButton = "ServicesHero-module--text-next-to-button--33c-a";
export var tabletSellCardContainer = "ServicesHero-module--tablet-sell-card-container--F1jhU";
export var sellWomanImage = "ServicesHero-module--sell-woman-image--2ZqAv";
export var tabletBuyCardContainer = "ServicesHero-module--tablet-buy-card-container--3lhFw";
export var buyWomanImage = "ServicesHero-module--buy-woman-image--q703U";
export var mobileTitleDefault = "ServicesHero-module--mobile-title-default--3EpHw";
export var mobileTitle = "ServicesHero-module--mobile-title--2Kfkj";
export var mobileTextDefault = "ServicesHero-module--mobile-text-default--_ujPd";
export var mobileBuyContentContainerBottom = "ServicesHero-module--mobile-buy-content-container-bottom--2485n";
export var mobileBuyTextNextToButton = "ServicesHero-module--mobile-buy-text-next-to-button--8AJxk";
export var mobileText = "ServicesHero-module--mobile-text--z-xBV";
export var mobileSellContentContainerBottom = "ServicesHero-module--mobile-sell-content-container-bottom--RYuSS";
export var mobileSellLowerContainer = "ServicesHero-module--mobile-sell-lower-container--9UcZJ";
export var mobileSellTextNextToButton = "ServicesHero-module--mobile-sell-text-next-to-button--YpmtC";
export var mobileSellWomanImage = "ServicesHero-module--mobile-sell-woman-image--RqDCf";
export var mobileBuyWomanImage = "ServicesHero-module--mobile-buy-woman-image--2SwUk";
export var mobileBuyLowerBackground = "ServicesHero-module--mobile-buy-lower-background--yoNBk";