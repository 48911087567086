import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import ServicesHero from "./components/ServicesHero"
import WhyCBS from "./components/WhyCBS"
import Testimonials from "./components/Testimonials"
import WhenToLiquidate from "./components/WhenToLiquidate"
import WordsFromManagement from "./components/WordsFromManagement"
import classNames from 'classnames'

import * as styles from "./Homepage.module.scss"

export const pageQuery = graphql`query HomeQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    html
    frontmatter {
      metaTitle
      metaDescription
      block1 {
        title
        body
      }
      block2 {
        title
        body
      }
      testimonials {
        author
        text
      }
    }
  }
  promoImages: allFile(
    filter: {relativeDirectory: {eq: "promos"}, sourceInstanceName: {eq: "homepageAssets"}}
    sort: {fields: name, order: ASC}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
}
`

const Homepage = ({ data }) => {
  const { promoImages, markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout className={styles.Homepage} slogan={true}>
      <SEO
        title={frontmatter.metaTitle}
        description={frontmatter.metaDescription}
      />

      <ServicesHero />

      <div className={styles.promoImagesWrapper}>
        <div className={styles.promoImagesContainer}>
            {promoImages.edges.map((item, index) => (
              <div className={styles.imageWrapper} key={index}>
                {index === 0 ?
                  <a href="https://www.uc.se/risksigill2?showorg=5568279367&language=swe" target="_blank" rel="noreferrer" >
                    <GatsbyImage
                      alt="UC logo"
                      image={item.node.childImageSharp.gatsbyImageData}
                      className={styles.promoImage}
                      placeholder="blurred"
                    />
                  </a> :
                  <GatsbyImage
                    alt="promo logo"
                    image={item.node.childImageSharp.gatsbyImageData}
                    className={index === 1 ? classNames(styles.promoImage, styles.promoImageGodaddy) : styles.promoImage}
                    placeholder="blurred"
                  />
                }
              </div>
            ))}
        </div>
      </div>

      <WhenToLiquidate />

      <WhyCBS />

      <Testimonials />

      <WordsFromManagement />

      <StaticImage
        className={styles.skeppsbroImage}
        src="../../../static/assets/Skeppsbro.jpg"
        alt="Skeppsbro view"
        height={381}
        layout="fixed"
        quality={100}
      />

      <StaticImage
        className={styles.skeppsbroImageMobile}
        src="../../../static/assets/Skeppsbro.jpg"
        alt="Skeppsbro view"
        height={513}
        layout="fixed"
        quality={100}
      />

    </Layout>
  );
}

export default Homepage
