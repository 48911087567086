// extracted by mini-css-extract-plugin
export var WhenToLiquidate = "WhenToLiquidate-module--WhenToLiquidate--3yVDe";
export var backgroundImage = "WhenToLiquidate-module--background-image--3GdJc";
export var contentContainer = "WhenToLiquidate-module--content-container--3cKoc";
export var titleTop = "WhenToLiquidate-module--title-top--3f7p7";
export var container = "WhenToLiquidate-module--container--1z6-k";
export var peopleImage = "WhenToLiquidate-module--people-image--1KZno";
export var badgeImage = "WhenToLiquidate-module--badge-image--3Yncx";
export var orangeBorder = "WhenToLiquidate-module--orange-border--1gOmo";
export var textContainer = "WhenToLiquidate-module--text-container--fjhSA";
export var title = "WhenToLiquidate-module--title--2amRD";
export var mobileTitle = "WhenToLiquidate-module--mobile-title--1lsiP";
export var mobileTitleBottom = "WhenToLiquidate-module--mobile-title-bottom--3sJkF";
export var mobileTitleTop = "WhenToLiquidate-module--mobile-title-top--NJuIa";
export var titleDesktop = "WhenToLiquidate-module--title-desktop--immpf";
export var titleBottom = "WhenToLiquidate-module--title-bottom--34giQ";
export var largeText = "WhenToLiquidate-module--large-text--2wd3G";
export var smallText = "WhenToLiquidate-module--small-text--2IN-H";