// extracted by mini-css-extract-plugin
export var WordsFromManagement = "WordsFromManagement-module--WordsFromManagement--5jZU-";
export var allContentContainer = "WordsFromManagement-module--all-content-container--WZaNz";
export var peopleAndSignaturesContainer = "WordsFromManagement-module--people-and-signatures-container--3KYfR";
export var personContainer = "WordsFromManagement-module--person-container--151R3";
export var signatureContainer = "WordsFromManagement-module--signature-container--22vSi";
export var orangeLineHorizontal = "WordsFromManagement-module--orange-line-horizontal--1lzt4";
export var name = "WordsFromManagement-module--name--2iTNX";
export var jobPosition = "WordsFromManagement-module--job-position--EQV3G";
export var allTextContainer = "WordsFromManagement-module--all-text-container--B7AAH";
export var titleContainer = "WordsFromManagement-module--title-container--3lRql";
export var orangeLineVertical = "WordsFromManagement-module--orange-line-vertical--2wCGv";
export var title = "WordsFromManagement-module--title--EOaDf";
export var text = "WordsFromManagement-module--text--2pp2I";
export var greenText = "WordsFromManagement-module--green-text--1oPsd";
export var quentinTextContainer = "WordsFromManagement-module--quentinTextContainer--1MIvi";
export var quentinTextMobileContainer = "WordsFromManagement-module--quentinTextMobileContainer--3CrPN";