import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import { convertToBgImage } from "../../../../utils/helper"

import * as styles from "./ServicesHero.module.scss"
import classNames from "classnames"
import CustomButton from "../../../../components/CustomButton"

const ServicesHero = () => {
  const data = useStaticQuery(graphql`
    {
      mobileBuyWomanImage: file(relativePath: { eq: "mobile-buy-woman.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: BLURRED)
        }
      }
      mobileSellWomanImage: file(
        relativePath: { eq: "mobile-sell-woman.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: BLURRED)
        }
      }
      tabletBuyWomanImage: file(relativePath: { eq: "tablet-buy-woman.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: BLURRED)
        }
      }
      tabletSellWomanImage: file(
        relativePath: { eq: "tablet-sell-woman.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: BLURRED)
        }
      }
      mobileBg: file(relativePath: { eq: "mobile-background.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      sellBg: file(relativePath: { eq: "sell-woman-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      buyBg: file(relativePath: { eq: "buy-woman-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const mobileBg = getImage(data.mobileBg)
  const mobileBgImage = convertToBgImage(mobileBg)

  const sellBg = getImage(data.sellBg)
  const sellBgImage = convertToBgImage(sellBg)

  const buyBg = getImage(data.buyBg)
  const buyBgImage = convertToBgImage(buyBg)

  return (
    <div className={styles.ServicesHero}>
      <div className={styles.regularDisplayContainer}>
        <BackgroundImage className={styles.sellCardContainer} {...sellBgImage}>
          <div className={styles.panelContainer}>
            <div className={styles.lowerPanelBackground}>
              <div className={styles.contentContainerHero}>
                <div className={styles.lowerPanel}>
                  <div className={styles.title}>
                    <Link to="/avveckla-aktiebolag" className={styles.link}>
                      Avveckla aktiebolag
                    </Link>
                  </div>
                  <div className={styles.lineSell} />
                  <div className={styles.text}>
                    Sälj ditt aktiebolag till oss idag för snabbavveckling.
                    <br /> Vi erbjuder snabbavveckling av aktiebolag till
                    marknadens mest attraktiva villkor!
                  </div>
                  <CustomButton
                    text={"SNABBAVVECKLING"}
                    link={"/avveckla-aktiebolag"}
                    whiteBgHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>

        <BackgroundImage className={styles.buyCardContainer} {...buyBgImage}>
          <div className={styles.panelContainer}>
            <div
              className={classNames(
                styles.lowerPanelBackground,
                styles.rightContainer
              )}
            >
              <div
                className={classNames(
                  styles.contentContainerHero,
                  styles.rightContent
                )}
              >
                <div className={styles.lowerPanel}>
                  <div className={styles.title}>
                    <Link to="/kopa-lagerbolag" className={styles.link}>
                      Köpa lagerbolag
                    </Link>
                  </div>
                  <div className={styles.lineBuy} />
                  <div className={styles.text}>
                    Hos oss kan du köpa lagerbolag till marknadsledande villkor!
                    Få tillgång till ditt
                    <br /> nya aktiebolag inom 2 timmar. En snabb start på ert
                    nya företag!
                  </div>
                  <div className={styles.hoverPopupContainer}>
                    <div>
                      <CustomButton
                        text={"lagerbolag"}
                        link={"/kopa-lagerbolag"}
                        whiteBgHover={true}
                      />
                    </div>
                    <div className={styles.hoverPopupTextContainer}>
                      <div className={styles.hoverPopupText}>NU MED E-LEG!</div>
                      <div className={styles.hoverPopupTextLine} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>

      {/* Tablet display */}
      <div className={styles.tabletDisplayContainer}>
        <BackgroundImage
          className={styles.tabletSellCardContainer}
          {...mobileBgImage}
          style={{ backgroundPosition: "left center" }}
        >
          <div className={styles.tabletContentContainer}>
            <div className={styles.tabletTitle}>Avveckla aktiebolag</div>
            <div className={styles.tabletLine} />
            <div className={styles.tabletText}>
              Sälj ditt aktiebolag till oss idag för snabbavveckling.
              Vi erbjuder snabbavveckling av aktiebolag till
              marknadens mest attraktiva villkor!
            </div>
            <div className={styles.lowerContainer}>
              <CustomButton
                text={"SNABBAVVECKLING"}
                link={"/avveckla-aktiebolag"}
              />
              <div className={styles.textNextToButton}>Svar på 2 tim!</div>
            </div>
          </div>
          <GatsbyImage
            image={data.tabletSellWomanImage.childImageSharp.gatsbyImageData}
            className={styles.sellWomanImage}
            alt="sales woman"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "left bottom",
            }}
          />
        </BackgroundImage>

        <BackgroundImage
          className={styles.tabletBuyCardContainer}
          {...mobileBgImage}
          style={{ backgroundPosition: "left center" }}
        >
          <div className={styles.tabletContentContainer}>
            <div className={styles.tabletTitle}>Köpa lagerbolag</div>
            <div className={styles.tabletLine} />
            <div className={styles.tabletText}>
              Hos oss kan du köpa lagerbolag till marknadsledande villkor! Få
              tillgång till ditt nya aktiebolag inom 2 timmar. En snabb start på
              ert nya företag!
            </div>
            <div className={styles.lowerContainer}>
              <div className={styles.textNextToButton}>Svar på 2 tim!</div>
              <CustomButton text={"lagerbolag"} link={"/kopa-lagerbolag"} />
            </div>
          </div>
          <GatsbyImage
            image={data.tabletBuyWomanImage.childImageSharp.gatsbyImageData}
            className={styles.buyWomanImage}
            alt="sales woman"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "left bottom",
            }}
          />
        </BackgroundImage>
      </div>

      {/* Mobile display */}
      <div className={styles.mobileDisplayContainer}>
        <BackgroundImage
          className={styles.mobileSellCardContainer}
          {...mobileBgImage}
          style={{ backgroundPosition: "left center" }}
        >
          <div className={styles.mobileSellContentContainerTop}>
            <div className={styles.mobileTitle}>
              Avveckla
              <br /> aktiebolag
            </div>
            <div className={styles.mobileLine} />
          </div>
          <div className={styles.mobileSellContentContainerBottom}>
            <div className={styles.mobileText}>
              Sälj ditt aktiebolag till oss idag för snabbavveckling.
              Vi erbjuder snabbavveckling av aktiebolag till
              marknadens mest attraktiva villkor!
            </div>
            <div className={styles.mobileSellLowerContainer}>
              <CustomButton
                text={"SNABBAVVECKLING"}
                link={"/avveckla-aktiebolag"}
                whiteBgHover={true}
                isBuySell={true}
              />
              <div className={styles.mobileSellTextNextToButton}>
                Svar på 2 tim!
              </div>
            </div>
          </div>
          <GatsbyImage
            image={data.mobileSellWomanImage.childImageSharp.gatsbyImageData}
            className={styles.mobileSellWomanImage}
            alt="sales woman"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "right bottom",
            }}
          />
        </BackgroundImage>

        <BackgroundImage
          className={styles.mobileBuyCardContainer}
          {...mobileBgImage}
          style={{ backgroundPosition: "left center" }}
        >
          <div className={styles.mobileBuyContentContainerTop}>
            <div className={styles.mobileTitle}>
              Köpa
              <br /> lagerbolag
            </div>
            <div className={styles.mobileLine} />
          </div>
          <div className={styles.mobileBuyContentContainerBottom}>
            <div className={styles.mobileText}>
              Hos oss kan du köpa lagerbolag till marknadsledande villkor! Få
              tillgång till ditt nya aktiebolag inom 2 timmar. En snabb start på
              ert nya företag!
            </div>
            <CustomButton
              text={"lagerbolag"}
              link={"/kopa-lagerbolag"}
              whiteBgHover={true}
              isBuySell={true}
            />
            <div className={styles.mobileBuyTextNextToButton}>
              Svar på 2 tim!
            </div>
          </div>
          <div className={styles.mobileBuyLowerBackground} />
          <GatsbyImage
            image={data.mobileBuyWomanImage.childImageSharp.gatsbyImageData}
            className={styles.mobileBuyWomanImage}
            alt="sales woman"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "left bottom",
            }}
          />
        </BackgroundImage>
      </div>
    </div>
  )
}

export default ServicesHero
