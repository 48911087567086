import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./WhenToLiquidate.module.scss"
import BackgroundImage from "gatsby-background-image"
import { StaticImage, getImage } from "gatsby-plugin-image"

import { convertToBgImage } from '../../../../utils/helper'

const WhenToLiquidate = () => {
  const data = useStaticQuery(graphql`{
    backgroundImage: file(relativePath: {eq: "when-to-liquidate-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`)
  
  const bgImage = getImage(data.backgroundImage)
  const backgroundImage = convertToBgImage(bgImage)
  
  return (
    <div className={styles.WhenToLiquidate}>
      <BackgroundImage
        Tag="section"
        className={styles.backgroundImage}
        {...backgroundImage}
      >
        <div className={styles.container}>
  
          <StaticImage
            className={styles.peopleImage}
            src="../../../../../static/assets/linda-stefan.png"
            alt="people"
            // width={479}
            height={510}
            layout="fixed"
            placeholder="blurred"
          />
          
          <StaticImage
            src="../../../../../static/assets/badge.png"
            className={styles.badgeImage}
            placeholder="blurred"
            alt="badge"
          />

          <div className={styles.contentContainer}>
            <div className={styles.orangeBorder} />

            <div className={styles.textContainer}>
              <h1 className={styles.titleDesktop}>
                <span className={styles.titleTop}>När du ska avveckla bolag </span>
                <span className={styles.titleBottom}>
                  eller köpa nytt aktiebolag
                </span>
              </h1>
              <h1 className={styles.mobileTitle}>
                <div className={styles.mobileTitleTop}>
                  <span>När du ska </span>
                  <br />
                  <span>avveckla bolag</span>
                </div>
                <div className={styles.mobileTitleBottom}>
                  <span>eller köpa nytt </span>
                  <br />
                  <span>aktiebolag</span>
                </div>
              </h1>
              <div className={styles.largeText}>
                Vi är ett registrerat företagsombud till Bolagsverket och har
                ett nära samarbete med dem.
                <br /> Du som kund kan därför alltid känna dig trygg i att vi
                levererar tjänster som lever upp till dina förväntningar.
              </div>
              <div className={styles.smallText}>
                Oavsett om du ska avveckla ett aktiebolag eller köpa ett
                lagerbolag så är vår ambition att alltid presentera det bästa
                erbjudandet på marknaden!
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default WhenToLiquidate
