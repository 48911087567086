import React from "react"

import * as styles from "./TestimonialsItem.module.scss"
import doubleQuotes from "/static/assets/doublequotes.svg"

const TestimonialsItem = ({ text, author, position }) => (
  <div className={styles.Testimonials}>
    <div className={styles.item}>
      <p className={styles.text}>
        <img
          src={doubleQuotes}
          alt="doubleQuotesleft"
          className={styles.quotesLeft}
        />
        {text}
        <img
          src={doubleQuotes}
          alt="doubleQuotesright"
          className={styles.quotesRight}
        />
      </p>
      <div className={styles.underLine}></div>
      <p className={styles.author}>{author}</p>
      <p className={styles.position}>{position}</p>
    </div>
  </div>
)

export default TestimonialsItem
