// extracted by mini-css-extract-plugin
export var MultilayerBgBlock = "Testimonials-module--MultilayerBgBlock--2tTWM";
export var wrapper = "Testimonials-module--wrapper--1kSGC";
export var lightGreenTrapezoid = "Testimonials-module--lightGreenTrapezoid--24PAH";
export var darkGreenTrapezoid = "Testimonials-module--darkGreenTrapezoid--bmFIV";
export var content = "Testimonials-module--content--3Ow9r";
export var inner = "Testimonials-module--inner--1G6fU";
export var title = "Testimonials-module--title--7Gdl2";
export var underLine = "Testimonials-module--underLine--2tW21";
export var text = "Testimonials-module--text--2TpN7";
export var button = "Testimonials-module--button--2pbqG";
export var testimonials = "Testimonials-module--testimonials--1qwSR";
export var bottom = "Testimonials-module--bottom--3-xUH";