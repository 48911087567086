import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from './WhyCBS.module.scss'

const WhyCBS = () => {

  const data = useStaticQuery(graphql`{
  badgeImage: file(relativePath: {eq: "why-cbs-badge.png"}) {
    childImageSharp {
      gatsbyImageData(width: 94, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
  clockImage: file(relativePath: {eq: "why-cbs-clock.png"}) {
    childImageSharp {
      gatsbyImageData(width: 111, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
  personImage: file(relativePath: {eq: "why-cbs-person.png"}) {
    childImageSharp {
      gatsbyImageData(width: 77, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
}
`)

  return (
    <div className={styles.WhyCBS}>
      <div className={styles.contentContainer}>
        <div className={styles.card}>
          <GatsbyImage
            image={data.badgeImage.childImageSharp.gatsbyImageData}
            className={styles.image}
            alt="badge icon"
          />

          <div className={styles.title}>
            Bästa erbjudande
          </div>

          <div className={styles.text}>
            Vår ambition är att ha marknadens bästa erbjudande. Jämför oss gärna!
          </div>
        </div>

        <div className={styles.card}>
          <GatsbyImage
            image={data.clockImage.childImageSharp.gatsbyImageData}
            className={styles.image}
            alt="Clock icon"
          />

          <div className={styles.title}>
            Snabb leverans
          </div>

          <div className={styles.text}>
            Vi är måna om att våra kunder ska få snabbast möjliga handläggning.
          </div>
        </div>

        <div className={styles.card}>
          <GatsbyImage
            image={data.personImage.childImageSharp.gatsbyImageData}
            className={styles.image}
            alt="Person icon"
          />

          <div className={styles.title}>
            Personlig service
          </div>

          <div className={styles.text}>
            Vi är tillgängliga per telefon och epost och svarar gärna på frågor och funderingar.
          </div>
        </div>
      </div>

    </div>
  );
}

export default WhyCBS
