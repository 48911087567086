import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import * as styles from './WordsFromManagement.module.scss'

const WordsFromManagement = () => {

  const data = useStaticQuery(graphql`{
  jakobImage: file(relativePath: {eq: "jakob.png"}) {
    childImageSharp {
      gatsbyImageData(width: 164, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
  torbjornImage: file(relativePath: {eq: "torbjorn.png"}) {
    childImageSharp {
      gatsbyImageData(width: 164, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
  jakobSignature: file(relativePath: {eq: "jakob-signature.png"}) {
    childImageSharp {
      gatsbyImageData(width: 119, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }
  torbjornSignature: file(relativePath: {eq: "torbjorn-signature.png"}) {
    childImageSharp {
      gatsbyImageData(width: 72, quality: 100, layout: FIXED, placeholder: BLURRED)
    }
  }  
}
`)

  return (
    <div className={styles.WordsFromManagement}>
      <div className={styles.allContentContainer}>
        <div className={styles.peopleAndSignaturesContainer}>
          <div className={styles.personContainer}>
            <div>
              <GatsbyImage
                image={data.jakobImage.childImageSharp.gatsbyImageData}
                alt="A photo of a man"
              />
            </div>

            <div className={styles.signatureContainer}>
              <div>
                <GatsbyImage
                  image={data.jakobSignature.childImageSharp.gatsbyImageData}
                  alt="Signature"
                />
              </div>
              <div className={styles.orangeLineHorizontal} />
              <div className={styles.name}>
                Jakob Lindquist
            </div>
              <div className={styles.jobPosition}>
                Affärsutveckling
            </div>
            </div>
          </div>

          <div className={styles.personContainer}>
            <div>
              <GatsbyImage
                image={data.torbjornImage.childImageSharp.gatsbyImageData}
                alt="A photo of a man"
              />
            </div>

            <div className={styles.signatureContainer}>
              <div>
                <GatsbyImage
                  image={data.torbjornSignature.childImageSharp.gatsbyImageData}
                  alt="Signature"
                />
              </div>
              <div className={styles.orangeLineHorizontal} />
              <div className={styles.name}>
                Torbjörn Nordberg
            </div>
              <div className={styles.jobPosition}>
                Jurist
            </div>
            </div>
          </div>
        </div>

        <div className={styles.allTextContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.orangeLineVertical} />
            <div className={styles.title}>
              Vår främsta målsättning på Citadellet Bolagsservice är att på ett professionellt sätt hantera alla uppdrag så att vi har 100% nöjda kunder, vare sig det gäller en snabbavveckling eller försäljning av ett lagerbolag.
            </div>
          </div>

          <div className={styles.text}>
            Detta uppnår vi genom att ständigt utveckla våra arbetsmetoder för att varje enskilt uppdrag ska genomföras på bästa möjliga sätt. Vi vill bygga och utveckla vår verksamhet tillsammans med våra medarbetare, kunder och partners och anser att absolut kundnöjdhet är det högsta betyget på vår framgång. Om du behöver avveckla ett aktiebolag eller köpa ett lagerbolag så kan du därför alltid räkna med ett effektivt och kunnigt bemötande från våra medarbetare!
            </div>
          <div className={styles.greenText}>
            Med dessa rader välkomnar vi alla nya och återkommande kunder till CBS.
            </div>
          <div className={styles.quentinTextContainer}>
            <StaticImage src="../../../../../static/assets/Quentin-text-homepage_small.png" alt="Vi ser fram emot att arbeta med er!" />
          </div>
          <div className={styles.quentinTextMobileContainer}>
            <StaticImage src="../../../../../static/assets/Quentin-text-homepage_mobile_small.png" alt="Vi ser fram emot att arbeta med er!" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WordsFromManagement
